import React from "react"

export const TableServersByClient = ({ serversByClientIx, client, server }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        fontFamily: "monospace",
        textAlign: "center",
      }}
    >
      <div
        style={{
          border: "1px solid #ccc",
          fontWeight: "bold",
        }}
      >
        <div style={{ padding: "5px" }}>Client</div>
        <div style={{ borderTop: "1px solid #ccc", padding: "5px" }}>
          Servers
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          overflow: "scroll",
          flex: "1 1 0px",
          paddingLeft: "1px",
        }}
      >
        {serversByClientIx.map((servers, ix) => {
          const style = {
            border: "1px solid #ccc",
            marginLeft: "-1px",
            flex: "1 1 0px",
          }
          if (ix === client) {
            style["border"] = "1px solid #000"
            style["zIndex"] = 10
          }
          const opacity = w => 0.1 + (w / 10) * 9
          return (
            <div key={ix} style={style}>
              <div style={{ padding: "5px" }}>{ix}</div>
              <div style={{ borderTop: "1px solid #ccc", padding: "5px" }}>
                {servers.map(s => (
                  <div
                    key={s.ix}
                    style={{
                      opacity: opacity(s.weight),
                      padding: "3px",
                      border: s.ix === server ? "1px solid #000" : "none",
                    }}
                  >
                    {s.ix}
                  </div>
                ))}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const TableClientsByServer = ({
  clientsByServerIx,
  client,
  server,
  ring,
  updateWeight,
  hitTable,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        fontFamily: "monospace",
        textAlign: "center",
      }}
    >
      <div
        style={{
          border: "1px solid #ccc",
          fontWeight: "bold",
        }}
      >
        <div style={{ padding: "5px" }}>Server</div>
        <div
          style={{
            borderTop: "1px solid #ccc",
            padding: "5px",
            height: "30px",
          }}
        >
          Weight
        </div>
        <div style={{ borderTop: "1px solid #ccc", padding: "5px" }}>Hits</div>
        <div style={{ borderTop: "1px solid #ccc", padding: "5px" }}>
          Clients
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          overflow: "scroll",
          flex: "1 1 0px",
          paddingLeft: "1px",
        }}
      >
        {clientsByServerIx.map((clients, ix) => {
          const style = {
            border: "1px solid #ccc",
            marginLeft: "-1px",
            flex: "1 1 0px",
          }
          if (ix === server) {
            style["border"] = "1px solid #000"
            style["zIndex"] = 10
          }

          return (
            <div key={ix} style={style}>
              <div style={{ padding: "5px" }}>{ix}</div>
              <div
                style={{
                  borderTop: "1px solid #ccc",
                  padding: "5px",
                  height: "30px",
                }}
              >
                <input
                  style={{ width: "50px" }}
                  type="number"
                  min={0}
                  max={1000}
                  value={ring.weights[ix]}
                  onChange={e => updateWeight(ix, parseInt(e.target.value, 10))}
                />
              </div>
              <div style={{ borderTop: "1px solid #ccc", padding: "5px" }}>
                {hitTable[ix]}
              </div>
              <div style={{ borderTop: "1px solid #ccc", padding: "5px" }}>
                {clients.map(s => (
                  <div
                    key={s}
                    style={{
                      padding: "3px",
                      border: s === client ? "1px solid #000" : "none",
                    }}
                  >
                    {s}
                  </div>
                ))}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
