import React, { useState, useEffect, useCallback, useRef } from "react"
import { Container, Element, SVG, Svg, Timeline } from "@svgdotjs/svg.js"
import { interpolateHclLong } from "d3-interpolate"

type ControlsProps = {
  speed: number
  playing: boolean
  done: boolean
  onPlayToggle: () => boolean
  onRestart: () => void
  onChangeSpeed: (speed: number) => void
}

function Controls({
  speed,
  playing,
  done,
  onRestart,
  onPlayToggle,
  onChangeSpeed,
}: ControlsProps) {
  return (
    <div className="controls">
      <p>
        <label>
          <span>Speed: {speed}</span>
          <br />
          <input
            type="range"
            min="1"
            max="10"
            value={speed}
            onChange={e => {
              const target = e.target as HTMLInputElement
              const newSpeed = Number.parseInt(target.value, 10)
              onChangeSpeed(newSpeed)
            }}
          />
        </label>
      </p>
      <p>
        <button disabled={done} onClick={onPlayToggle}>
          {playing ? "Pause" : "Play"}
        </button>{" "}
        <button onClick={onRestart}>Restart</button>
      </p>
    </div>
  )
}

export function App() {
  const [canvasState, setCanvasState] = useState<State | null>(null)
  const [playing, setPlaying] = useState(false)
  const [speed, setSpeed] = useState(10)
  const canvasRef = useRef<HTMLDivElement>(null)

  const [, updateFakeState] = useState<{}>()
  const forceUpdate = useCallback(() => updateFakeState({}), [])

  const createCanvasState = () => {
    const state = new State(SVG().addTo(canvasRef.current!))

    state.playing = playing
    state.invSpeed = 1 / speed
    state.onStateChange(() => {
      forceUpdate()
    })

    setCanvasState(state)
  }

  useEffect(() => {
    if (!canvasRef.current) {
      // We need to wait for the first render to get the canvasRef.
      return
    }

    if (!canvasState) {
      createCanvasState()
      return
    }

    run(canvasState).catch(e => {
      if (e) {
        throw e
      }
    })

    return () => {
      if (canvasState) canvasState.destroy()
    }
  }, [canvasState, canvasRef.current])

  // Not yet ready to render.
  return (
    <>
      {canvasState && (
        <Controls
          speed={speed}
          playing={playing}
          done={canvasState?.done}
          onRestart={createCanvasState}
          onPlayToggle={() => {
            canvasState.playing = !canvasState.playing
            setPlaying(canvasState.playing)
            canvasState.notifyStateChange()
            return canvasState.playing
          }}
          onChangeSpeed={speed => {
            setSpeed(speed)
            canvasState.invSpeed = 1 / speed
            canvasState.notifyStateChange()
          }}
        />
      )}
      <div ref={canvasRef} />
    </>
  )
}

// The layout:
// |-space-| column 1 |-space-| column 2 |-space-| column 3 |-space-|
// |  35px |   380px  |  35px |   380px  |  35px |   380px  |  35px |
class Layout {
  readonly viewBox = { width: 1280, height: 960 }

  readonly columnWidth = 380
  readonly columnSeparator = 35
  readonly columnHeight = this.viewBox.height - 2 * this.columnSeparator

  columnX(i: number): number {
    return (i + 1) * this.columnSeparator + i * this.columnWidth
  }
}

class State {
  readonly haystackCardinality = 120
  readonly workerCardinality = 4

  readonly layout = new Layout()
  readonly canvas: Svg

  invSpeed: number = 1
  playing: boolean = true
  done: boolean = false
  random: () => number

  private stateChangeListeners: (() => void)[] = []

  constructor(canvas: Svg) {
    this.canvas = canvas

    this.canvas
      .viewbox(0, 0, this.layout.viewBox.width, this.layout.viewBox.height)
      .size(this.layout.viewBox.width, this.layout.viewBox.height)
      .css({
        margin: "0 auto",
        display: "block",
        width: "100%",
        height: "auto",
      })

    this.random = Math.random
  }

  destroy() {
    this.done = true
    this.playing = false
    this.notifyStateChange()
    this.canvas.remove()
  }

  onStateChange(f: () => void) {
    this.stateChangeListeners.push(f)
  }

  notifyStateChange() {
    this.stateChangeListeners.forEach(f => f())
  }

  doYield() {
    if (!this.done) {
      return new Promise(requestAnimationFrame)
    } else {
      // We're done, reject is the cancellation token.
      return Promise.reject()
    }
  }
}

async function run(state: State) {
  const canvas = state.canvas

  // Draw the haystack.
  const haystackParent = canvas
  const haystackSize = {
    width: state.layout.columnWidth,
    height: +haystackParent.height() - 2 * state.layout.columnSeparator,
  }
  const haystackElementHeight = haystackSize.height / state.haystackCardinality
  const haystack = haystackParent
    .nested()
    .x(state.layout.columnX(0))
    .y(state.layout.columnSeparator)
  const marginToHeightRatio = 1 / 2

  const haystackValues = Array.from(
    { length: state.haystackCardinality },
    _ => haystackSize.width * state.random(),
  )

  for (let i = 0; i < state.haystackCardinality; i++) {
    const value = haystackValues[i]

    haystack
      .rect(value, haystackElementHeight * marginToHeightRatio)
      .y(i * haystackElementHeight)
      .data("value", value)
      .fill("#db8758")
  }

  // Draw the scratch.
  const scratchParent = canvas

  // Draw the CPUs for the first stage.
  const workersParent = canvas
  const workers = workersParent
    .nested()
    .x(state.layout.columnX(2))
    .y(state.layout.columnSeparator)

  const workerSize = Math.min(
    state.layout.columnWidth / 2,
    (state.layout.columnHeight / state.workerCardinality) * 0.9,
  )

  const workerSep =
    (state.layout.columnHeight - state.workerCardinality * workerSize) /
    (state.workerCardinality - 1)

  const workerColors: Array<string> = []

  const interpolateColor = interpolateHclLong("#9c1a04", "#2583C6")
  for (let i = 0; i < state.workerCardinality; i++) {
    workerColors.push(interpolateColor(i / state.workerCardinality))

    new Worker(
      state,
      workers
        .nested()
        .x(0)
        .y(i * (workerSize + workerSep))
        .height(workerSize)
        .width(state.layout.columnWidth),
      { color: workerColors[i] },
    )
  }

  // Draw scratch after workers to have elements overlay on top.
  const scratch = scratchParent
    .nested()
    .x(state.layout.columnX(1))
    .y(state.layout.columnSeparator)

  const inputTasks: Array<FileSpan> = []

  while (!state.playing) {
    await state.doYield()
  }

  // Stage 1.
  {
    let idleWorkers: Array<number> = (() => {
      let w: Array<number> = []
      for (let i = 0; i < state.workerCardinality; i++) {
        w.push(i)
      }
      return w
    })()

    let activeWorkers: Array<Stage1Worker> = []

    const tick = async (): Promise<boolean> => {
      let i = 0
      while (i < activeWorkers.length) {
        if (!(await activeWorkers[i].tick())) {
          for (let w of activeWorkers.splice(i, 1)) {
            idleWorkers.push(w.id)
          }
        } else {
          i++
        }
      }

      await state.doYield()

      return activeWorkers.length > 0
    }

    const haystackBlockSize = 10

    const caretVerticalOffset = state.layout.columnSeparator - 13.5
    const haystackCaret = SVG(IconCaret)
      .size(30, 30)
      .fill("#340701")
      .x(state.layout.columnSeparator - 24)
      .y(caretVerticalOffset)
    canvas.add(haystackCaret)

    for (
      let haystackPos = 0;
      haystackPos < state.haystackCardinality;
      haystackPos += haystackBlockSize
    ) {
      const workerIx = idleWorkers.shift()!

      // Find the elements to sort.
      const blockElements = haystack
        .children()
        .toArray()
        .slice(haystackPos, haystackPos + haystackBlockSize)

      const task: FileSpan = {
        start: haystackPos,
        end: haystackPos + blockElements.length,
      }

      inputTasks.push(task)

      // create the block to animate
      let block = haystack.nested().group()
      blockElements.forEach(e => e.clone().addTo(block))

      // Init the worker.
      activeWorkers.push(
        new Stage1Worker(
          state,
          workerIx,
          block,
          workers.get(workerIx),
          scratch,
        ),
      )

      haystackCaret.y(caretVerticalOffset + +blockElements.slice(-1)[0].y())
      if (haystackPos + haystackBlockSize >= state.haystackCardinality) {
        haystackCaret.dy(+5)
        haystackCaret.fill("#ccc")
      }

      while (!idleWorkers.length) {
        await tick()
      }
    }

    // Run all remaining steps.
    while (await tick());

    haystackCaret.remove()
  }

  while (!state.playing) {
    await state.doYield()
  }

  // Stage 1 to 2 transition.
  {
    const timeline = new Timeline()
    haystack
      .timeline(timeline)
      .animate(500 * state.invSpeed, 0, "now")
      .x(-state.layout.columnWidth)
    scratch
      .timeline(timeline)
      .animate(500 * state.invSpeed, 0, "now")
      .x(state.layout.columnSeparator)
    timeline.play()

    while (timeline.active()) {
      await state.doYield()
    }

    haystack.remove()

    // fix ordering
    scratch.children().forEach(g => {
      ;(g as Container).ungroup(scratch)
    })
    const tmpChildren = scratch.children()
    scratch.clear()
    tmpChildren.sort((a, b) => +a.y() - +b.y()).forEach(e => e.addTo(scratch))
  }

  while (!state.playing) {
    await state.doYield()
  }

  // Stage 2.1.
  let globalPivots

  {
    const physicalElHeight = haystackElementHeight * marginToHeightRatio
    const physicalElSpace = haystackElementHeight * (1 - marginToHeightRatio)
    let worker = new Stage2Step1Worker(
      state,
      scratch,
      workers.get(0),
      physicalElHeight,
      physicalElSpace,
      workerColors,
    )
    while (await worker.tick()) {
      await state.doYield()
    }

    // Restore elements colors since we're not interested in the
    // candidates anymore.
    scratch.find(".pivot-candidate").forEach(el => {
      el.removeClass("pivot-candidate").fill("#db8758")
    })

    globalPivots = worker.block

    globalPivots.children().forEach((el, ix) => {
      el.fill(workerColors[ix])
    })
  }

  // TBD after we find pivot locations.
  const outputTasks: Array<MergeTask> = []
  for (let i = 0; i < state.workerCardinality; i++) {
    outputTasks.push({
      start: 0,
      end: 0,
      inputs: [],
    })
  }

  while (!state.playing) {
    await state.doYield()
  }

  // Stage 2.2.
  {
    const localPivotClone = globalPivots.clone()
    globalPivots.remove()

    const taskIndicators: Array<Element> = []

    // Let works do the work.
    let idleWorkers: Array<number> = (() => {
      let w: Array<number> = []
      for (let i = 0; i < state.workerCardinality; i++) {
        w.push(i)
      }
      return w
    })()

    let activeWorkers: Array<Stage2Step2Worker> = []

    const tick = async (): Promise<boolean> => {
      let i = 0

      while (i < activeWorkers.length) {
        const worker = activeWorkers[i]

        if (!(await worker.tick())) {
          // Process the result
          const taskSize = worker.task.end - worker.task.start
          const result = worker.step0_result

          let offset = 0
          for (let j = 0; j < result.length; j++) {
            const count = result[j] - offset
            if (count === 0) {
              continue
            }

            outputTasks[j].end += count

            outputTasks[j].inputs.push({
              start: worker.task.start + offset,
              end: worker.task.start + result[j],
            })

            offset = result[j]

            if (result[j] == taskSize) {
              break
            }
          }

          if (worker.task.start + offset < worker.task.end) {
            outputTasks[outputTasks.length - 1].inputs.push({
              start: worker.task.start + offset,
              end: worker.task.end,
            })
          }

          // Mark the w as idle.
          for (let w of activeWorkers.splice(i, 1)) {
            idleWorkers.push(w.id)
          }
        } else {
          i++
        }
      }

      await state.doYield()

      return activeWorkers.length > 0
    }

    let taskIx = 0
    while (inputTasks.length) {
      const task = inputTasks.shift()!
      const workerIx = idleWorkers.shift()!

      const start = scratch.get(task.start)
      const end = scratch.get(task.end - 1)

      taskIndicators.push(
        scratch
          .rect(
            haystackElementHeight * marginToHeightRatio,
            +end.y() - +start.y() + haystackElementHeight * marginToHeightRatio,
          )
          .y(start.y())
          .fill(workerColors[workerIx]),
      )

      const worker = workers.get(workerIx)

      worker.add(
        localPivotClone
          .clone()
          .y(+worker.height() / 2 - +localPivotClone.height() / 2)
          .addClass("pivots"),
      )
      activeWorkers.push(
        new Stage2Step2Worker(
          state,
          taskIx,
          workerIx,
          worker,
          task,
          scratch,
          worker.find(".pivots")[0],
          taskIndicators,
        ),
      )

      taskIx += 1

      while (!idleWorkers.length) {
        await tick()
      }
    }

    while (await tick()) {
      await state.doYield()
    }

    let runningSum = outputTasks[outputTasks.length - 1].end
    for (let i = 0; i < outputTasks.length; i++) {
      outputTasks[i].start += runningSum
      outputTasks[i].end += runningSum

      runningSum += outputTasks[i].end - outputTasks[i].start
    }

    // Update last task.
    outputTasks.slice(-1)[0].start = outputTasks.slice(-2)[0].end
    outputTasks.slice(-1)[0].end = state.haystackCardinality

    // Cleanup.
    taskIndicators.forEach(e => e.remove())
  }
  while (!state.playing) {
    await state.doYield()
  }

  // Stage 3.
  const output = canvas
    .nested()
    .x(state.layout.columnX(1))
    .y(state.layout.columnSeparator)
  {
    const perWorkerIndicators: Array<Element> = []

    for (let i = 0; i < state.workerCardinality; i++) {
      const t = outputTasks[i]

      const first = scratch.get(t.start)
      const last = scratch.get(t.end - 1)

      perWorkerIndicators.push(
        output
          .rect(+first.height(), +last.y() - +first.y() + +last.height())
          .y(first.y())
          .fill(workerColors[i]),
      )
    }

    // Stage3Worker
    let activeWorkers: Array<Stage3Worker> = []

    const tick = async (): Promise<boolean> => {
      let i = 0
      while (i < activeWorkers.length) {
        if (!(await activeWorkers[i].tick())) {
          activeWorkers.splice(i, 1)
        } else {
          i++
        }
      }

      await state.doYield()

      return activeWorkers.length > 0
    }

    // Init the worker.
    for (let workerIx = 0; workerIx < state.workerCardinality; workerIx++) {
      activeWorkers.push(
        new Stage3Worker(
          state,
          workerIx,
          workers.get(workerIx),
          workerColors[workerIx],
          scratch,
          output,
          outputTasks[workerIx],
          haystackElementHeight,
        ),
      )
    }

    // Run all remaining steps.
    while (await tick()) {
      await state.doYield()
    }

    perWorkerIndicators.forEach(e => e.remove())
  }

  // Hooray!
  state.done = true
  state.notifyStateChange()
}

abstract class WorkerThread {
  state: State
  id: number
  running: boolean

  constructor(state: State, id: number) {
    this.state = state
    this.id = id
    this.running = true
  }

  abstract tick(): Promise<boolean>
}

class Worker {
  timeline: Timeline

  constructor(state: State, container: Container, props: { color: string }) {
    this.timeline = new Timeline()

    const busyIndicator = SVG()
      .rect(4, 4)
      .x(13)
      .y(7)
      .fill(props.color)
      .addClass("busy")
      .hide()

    container.add(
      SVG(IconCPU)
        .size(container.height())
        .x(+container.width() / 2)
        .stroke(props.color)
        .add(busyIndicator),
    )

    busyIndicator
      .timeline(this.timeline)
      .animate(
        100 * state.invSpeed,
        (50 + 100 * state.random()) * state.invSpeed,
      )
      .css({ opacity: 0 })
      .animate(
        100 * state.invSpeed,
        (50 + 100 * state.random()) * state.invSpeed,
      )
      .css({ opacity: 1 })
      .loop(Number.MAX_SAFE_INTEGER)

    this.timeline.play()

    state.onStateChange(() => {
      if (state.playing) {
        this.timeline.play()
      } else {
        this.timeline.pause()
      }
    })
  }
}

class Stage1Worker extends WorkerThread {
  block: Container
  worker: Element
  scratch: Container
  sourceVerticalOffset: number

  anim: Timeline
  sortedIxs: Array<number> | undefined
  step = 0

  constructor(
    state: State,
    index: number,
    block: Container,
    worker: Element,
    scratch: Container,
  ) {
    super(state, index)

    this.block = block
    this.worker = worker
    this.scratch = scratch

    const workerPos = absolutePosition(worker)
    const blockParentPos = absolutePosition(block.parents()[0])
    this.sourceVerticalOffset = +block.y()

    // Let them overlap with the workers.
    const size = fitSize(elSize(this.block), elSize(worker))

    const xPos = workerPos.x - blockParentPos.x

    // Vertical alignment is necessary.
    const yPos =
      workerPos.y -
      blockParentPos.y +
      +worker.height() / 2 -
      +block.height() / 2

    this.anim = new Timeline()

    this.block
      .timeline(this.anim)
      .animate(state.invSpeed * 2000)
      .x(xPos)
      .y(yPos)
      .size(size.width, size.height)

    this.anim.play()
  }

  async tick(): Promise<boolean> {
    if (!this.state.playing) {
      if (this.running) {
        this.running = false
        this.anim.pause()
      }
      return true
    } else if (!this.running) {
      this.running = true
      this.anim.play()
      return true
    }

    if (this.anim.active()) {
      return true
    }

    if (this.step == 0) {
      this.worker.find(".busy")[0].show()

      this.step = 1

      // Sort children using their width. Then, animate their
      // repositioning.
      this.sortedIxs = this.block
        .children()
        .toArray()
        .map((e, ix) => ({ ix, value: +e.width() }))
        .sort(({ value: a }, { value: b }) => {
          return a - b
        })
        .map(({ ix }) => ix)

      this.anim = new Timeline()

      this.sortedIxs.map((src, dest) => {
        this.block
          .get(src)
          .timeline(this.anim)
          .animate((250 + Math.random() * 1000) * this.state.invSpeed, 0, "now")
          .y(+this.block.get(dest).y())
      })

      this.anim.play()

      return true
    }

    if (this.step == 1) {
      this.step = 2

      // Pick pivots.
      const num_ranges = this.state.workerCardinality
      const pivots = Math.min(num_ranges - 1, this.block.children().length - 1)
      const range_size = Math.floor(this.block.children().length / num_ranges)
      const rem = this.block.children().length % num_ranges

      const rangeSizes = Array.from(
        { length: pivots + 1 },
        (_, i) => range_size + +(i < rem),
      )
      shuffleArray(rangeSizes)

      // Drop the last pivot after shuffle as it is implicitly accounted for.
      // We needed it only to properly distribute the `rem` elements.
      rangeSizes.pop()

      let offset = 0
      for (let sz of rangeSizes) {
        offset += sz
        this.block
          .get(this.sortedIxs![offset])
          .fill("#b13d14")
          .addClass("pivot-candidate")
      }

      // Persistence.
      // First, move to scratch and re-position relatively to the new
      // parent.
      this.block
        .addTo(this.scratch)
        .dx(-this.scratch.x() + this.state.layout.columnSeparator)

      this.worker.find(".busy")[0].hide()

      // Second, animate it.
      this.anim = new Timeline()
      this.block
        .timeline(this.anim)
        .animate(this.state.invSpeed * 2000)
        .x(0)
        .y(this.sourceVerticalOffset)
      this.anim.play()

      return true
    }

    return false
  }
}

class Stage2Step1Worker extends WorkerThread {
  scratch: Container
  worker: Element

  anim: Timeline
  step = 0

  block: Container
  sortedIxs: Array<number> | undefined

  elHeight: number
  elSpacing: number

  colors: Array<string>

  constructor(
    state: State,
    scratch: Container,
    worker: Element,
    elHeight: number,
    elSpacing: number,
    colors: Array<string>,
  ) {
    super(state, 0)

    this.scratch = scratch
    this.worker = worker

    this.elHeight = elHeight
    this.elSpacing = elSpacing
    this.colors = colors

    this.anim = new Timeline()

    let pivotCandidates = scratch.nested().group()
    this.block = pivotCandidates

    // Hack: sort them by their Y position to fix
    // order for the animation to follow.
    let candidateEls = scratch.find(".pivot-candidate").sort((a, b) => {
      return +a.y() - +b.y()
    })

    for (const e of candidateEls) {
      pivotCandidates.add(e.clone())
    }

    const workerPos = absolutePosition(worker)
    const scratchPos = absolutePosition(scratch)

    const xPos = workerPos.x - scratchPos.x + 20 // +20 padding for caret which will be used in the next step.

    pivotCandidates
      .timeline(this.anim)
      .animate(1000 * this.state.invSpeed, 0, "now")
      .x(xPos)

    pivotCandidates.children().forEach((el, ix) => {
      el.timeline(this.anim)
        .animate(1000 * this.state.invSpeed, 0, "now")
        .y(ix * (elHeight + elSpacing))
    })

    this.anim.play()

    this.worker.find(".busy")[0].show()
  }

  async tick(): Promise<boolean> {
    if (!this.state.playing) {
      if (this.running) {
        this.running = false
        this.anim.pause()
      }
      return true
    } else if (!this.running) {
      this.running = true
      this.anim.play()
      return true
    }

    if (this.anim.active()) {
      return true
    }

    if (this.step == 0) {
      this.step = 1

      // Animate pivot sorting.
      this.sortedIxs = this.block
        .children()
        .toArray()
        .map((e, ix) => ({ ix, value: +e.width() }))
        .sort(({ value: a }, { value: b }) => {
          return a - b
        })
        .map(({ ix }) => ix)

      const timeline = new Timeline()

      this.sortedIxs.map((src, dest) => {
        timeline
          .schedule(
            this.block
              .get(src)
              .animate((250 + Math.random() * 1000) * this.state.invSpeed)
              .y(+this.block.get(dest).y()),
            250,
            "now",
          )
          .schedule(this.block.animate(0), 0, "last")
      })

      timeline.play()

      this.anim = timeline

      return true
    }

    if (this.step == 1) {
      this.step += 1

      // Resample pivots.
      const cardinality = this.block.children().length
      const num_pivots = this.state.workerCardinality - 1
      const everyN = Math.floor(cardinality / (num_pivots + 1))
      const remainder = cardinality % num_pivots

      let offset = 0
      for (let i = 0; i < num_pivots; i++) {
        offset += everyN + +(remainder > i)

        // Here we clone to add global pivots at the end of the
        // list to avoid having to shuffle them in the DOM later.
        this.block
          .get(this.sortedIxs![offset])
          .fill(this.colors[i])
          .removeClass("pivot-candidate")
          .clone()
          .addClass("pivot-global")
          .addTo(this.block)
      }

      this.anim = new Timeline()
      this.block.timeline(this.anim).animate(1000 * this.state.invSpeed)
      this.anim.play()

      return true
    }

    if (this.step == 2) {
      this.step += 1

      this.block.children().forEach(el => {
        if (!el.hasClass("pivot-global")) {
          el.remove()
        }
      })

      this.anim = new Timeline()
      this.block.children().forEach((el, ix) => {
        el.timeline(this.anim)
          .animate(500 * this.state.invSpeed, 0, "now")
          .y(ix * (this.elHeight + this.elSpacing))
      })

      // vertical align
      const futureHeight =
        this.block.children().length * (this.elHeight + this.elSpacing) -
        this.elSpacing
      this.block
        .timeline(this.anim)
        .animate(500 * this.state.invSpeed, 0, "now")
        .y(+this.worker.height() / 2 - futureHeight / 2)
        .animate(500 * this.state.invSpeed)

      this.anim.play()

      this.worker.find(".busy")[0].hide()

      return true
    }

    return false
  }
}

class Stage2Step2Worker extends WorkerThread {
  taskIx: number
  worker: Element
  task: FileSpan
  scratch: Container
  pivots: Element
  taskIndicators: Array<Element>

  nextPivotIx = 0

  caret: Element
  currentPivotStep = -1

  // step 0 state
  step0_cmp_to: Element | undefined
  step0_low = 0
  step0_high = 0
  step0_mid = 0
  step0_result: Array<number> = []

  anim: Timeline

  constructor(
    state: State,
    taskIx: number,
    index: number,
    worker: Element,
    task: FileSpan,
    scratch: Container,
    pivots: Element,
    taskIndicators: Array<Element>,
  ) {
    super(state, index)

    this.taskIx = taskIx
    this.worker = worker
    this.task = task
    this.scratch = scratch
    this.pivots = pivots

    this.taskIndicators = taskIndicators

    this.caret = SVG(IconCaret)
      .addTo(this.worker)
      .size(20, 20)
      .x(0)
      .y(15 - +this.pivots.children()[0].y())

    this.pivots.x(20)

    this.anim = new Timeline()
    this.anim.play()
  }

  async tick(): Promise<boolean> {
    if (!this.state.playing) {
      if (this.running) {
        this.running = false
        this.anim.pause()
      }
      return true
    } else if (!this.running) {
      this.running = true
      this.anim.play()
      return true
    }

    if (this.anim.active()) {
      return true
    }

    if (this.currentPivotStep === 0) {
      this.worker.find(".busy")[0].show()

      // Lower-bound.
      if (this.step0_low <= this.step0_high) {
        if (this.step0_cmp_to) {
          this.step0_cmp_to.remove()
          this.step0_cmp_to = undefined
        }

        this.step0_mid = Math.floor((this.step0_low + this.step0_high) / 2)

        this.step0_cmp_to = this.scratch
          .get(this.task.start + this.step0_mid)
          .clone()
          .addTo(this.scratch)

        const workerPos = absolutePosition(this.worker)
        const cmpToPos = absolutePosition(this.scratch)

        this.anim = new Timeline()
        this.step0_cmp_to
          .timeline(this.anim)
          .animate(50 + 100 * Math.random() * this.state.invSpeed)
          .x(20 + workerPos.x - cmpToPos.x)
          .y(
            workerPos.y -
              cmpToPos.y +
              +this.pivots.get(this.nextPivotIx - 1).y(),
          )
          .animate(100 * this.state.invSpeed)
        this.anim.play()
      }
      this.currentPivotStep += 1

      return true
    }

    if (this.currentPivotStep === 1) {
      const pivotValue = this.pivots.get(this.nextPivotIx - 1).data("value")
      const cmpToValue = this.scratch
        .get(this.task.start + this.step0_mid)
        .data("value")

      if (this.step0_low < this.step0_high) {
        if (cmpToValue < pivotValue) {
          this.step0_low = this.step0_mid + 1
        } else {
          this.step0_high = this.step0_mid
        }

        this.currentPivotStep -= 1
      } else {
        // Overlay the pivot visually.
        const toOverlay = this.scratch
          .get(this.task.start + this.step0_low)
          .clone()
          .fill(this.pivots.get(this.nextPivotIx - 1).fill())
        toOverlay.addTo(this.scratch)

        this.step0_result.push(this.step0_low)
        this.currentPivotStep += 1
      }

      return true
    }

    if (this.nextPivotIx - 1 < this.pivots.children().length - 1) {
      this.currentPivotStep = 0
      this.step0_low = 0
      this.step0_high = this.task.end - this.task.start

      this.nextPivotIx += 1

      this.caret.y(-8 + +this.pivots.get(this.nextPivotIx - 1).y())

      return true
    }

    this.step0_cmp_to!.remove()
    this.caret.remove()
    this.pivots.remove()
    this.taskIndicators[this.taskIx].fill("#ccc")
    this.worker.find(".busy")[0].hide()

    return false
  }
}

class Stage3Worker extends WorkerThread {
  worker: Element
  color: string
  scratch: Container
  output: Container
  task: MergeTask
  elHeight: number

  anim: Timeline

  // state
  outputOffset = 0

  constructor(
    state: State,
    workerIx: number,
    worker: Element,
    color: string,
    scratch: Container,
    output: Container,
    task: MergeTask,
    elHeight: number,
  ) {
    super(state, workerIx)

    this.worker = worker
    this.color = color
    this.scratch = scratch
    this.output = output
    this.task = task
    this.elHeight = elHeight

    this.anim = new Timeline()
    this.anim.play()

    this.worker.find(".busy")[0].show()
  }

  async tick(): Promise<boolean> {
    if (!this.state.playing) {
      if (this.running) {
        this.running = false
        this.anim.pause()
      }
      return true
    } else if (!this.running) {
      this.running = true
      this.anim.play()
      return true
    }

    if (this.anim.active()) {
      return true
    }

    if (this.task.inputs.length) {
      let minInputIx = 0
      let minValue = this.scratch
        .get(this.task.inputs[minInputIx].start)
        .data("value")

      // Find the min of all the inputs.
      for (let i = 0; i < this.task.inputs.length; i++) {
        const candidate = this.scratch
          .get(this.task.inputs[i].start)
          .data("value")
        if (candidate < minValue) {
          minInputIx = i
          minValue = candidate
        }
      }

      // Remove the min.
      const minScratchPos = this.task.inputs[minInputIx].start
      this.task.inputs[minInputIx].start += 1

      if (
        this.task.inputs[minInputIx].start == this.task.inputs[minInputIx].end
      ) {
        this.task.inputs.splice(minInputIx, 1)
      }

      const value = this.scratch.get(minScratchPos).clone().fill(this.color)
      value.addTo(this.scratch)

      const workerPos = absolutePosition(this.worker)
      const elPos = absolutePosition(this.scratch)

      this.anim = new Timeline()
      value
        .timeline(this.anim)
        .animate((100 + 50 * Math.random()) * this.state.invSpeed)
        .x(workerPos.x - elPos.x)
        .y(workerPos.y - elPos.y + +this.worker.height() / 2)
        .animate((100 + 50 * Math.random()) * this.state.invSpeed)
        .x(+this.output.x() - this.state.layout.columnSeparator)
        .y((this.task.start + this.outputOffset) * this.elHeight)
        .after(() => {
          value.addTo(this.output).x(0)
        })
      this.anim.play()

      this.outputOffset += 1

      return true
    }

    this.worker.find(".busy")[0].hide()

    return false
  }
}

function absolutePosition(e: Element): { x: number; y: number } {
  let x = +e.x()
  let y = +e.y()

  for (const p of e.parents()) {
    x += +p.x()
    y += +p.y()
  }

  return { x, y }
}

type Size = {
  width: number
  height: number
}

function elSize(el: Element | Container): Size {
  return {
    width: +el.width(),
    height: +el.height(),
  }
}

function fitSize(inner: Size, outer: Size) {
  const result: Size = { ...inner }

  if (inner.height > outer.height) {
    result.width *= outer.height / inner.height
    result.height = outer.height
  } else if (inner.width > outer.width) {
    result.height *= outer.width / inner.width
    result.width = outer.width
  }

  return result
}

function shuffleArray<T>(array: Array<T>) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
}

type FileSpan = {
  start: number
  end: number
}

type MergeTask = {
  start: number
  end: number
  inputs: Array<FileSpan>
}

const IconCPU = `<svg aria-label="Cpu" viewBox="0 0 24 24"><path stroke-width="1" fill="none" d="M1 18h3m-3-4h3m-3-4h3M1 6h3m16 12h3m-3-4h3m-3-4h3m-3-4h3M6 1v3m4-3v3m4-3v3m4-3v3M6 20v3m4-3v3m4-3v3m4-3v3M5 20h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1zm8-13h4v4h-4V7z"></path></svg>`

const IconCaret = `<svg aria-label="CaretRightFill" viewBox="0 0 24 24"><path d="M9 6v12l6-6z"></path></svg>`

// Colophon
//
// - https://www.color-hex.com/color-palette/13450
// - https://icons.grommet.io/
