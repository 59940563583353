import React, { Component } from "react"
import { Svg, SVG } from "@svgdotjs/svg.js"
import { interpolateRgb } from "d3-interpolate"

// @ts-ignore
import { physicalApertureWidth } from "./wda/wda"
// @ts-ignore
import { polarToCartesian } from "./geometry"
// @ts-ignore
import { describeArc } from "./svgutils"

type VisProps = {
  clientCount: number
  serverCount: number

  client: number
  server: number

  aperture: number

  clientsByServerIx: Array<Array<number>>
  serversByClientIx: Array<Array<{ ix: number; weight: number }>>

  selectClient: (client: number | null) => void
  selectServer: (client: number | null) => void

  ring: {
    ringWeights: Array<number>
    weights: Array<number>
  }
}

export default class Vis extends Component<VisProps> {
  svgWrapper: React.RefObject<HTMLDivElement>
  svg: React.RefObject<HTMLDivElement>
  draw: Svg | undefined

  constructor(props: VisProps) {
    super(props)

    this.svgWrapper = React.createRef()
    this.svg = React.createRef()
  }

  wrapperClickHandler = () => {
    this.props.selectClient(null)
    this.props.selectServer(null)
  }

  componentDidMount() {
    if (!this.svgWrapper.current) {
      return
    }

    this.svgWrapper.current.addEventListener("click", this.wrapperClickHandler)

    this.draw = SVG()
      .addTo(this.svg.current!)
      .size("100%", "100%")
      .viewbox(0, 0, 600, 600)

    this.redrawSvgElements()
  }

  componentWillUnmount() {
    if (this.svgWrapper.current) {
      this.svgWrapper.current.removeEventListener(
        "click",
        this.wrapperClickHandler,
      )
    }
    if (this.draw) {
      this.draw.clear()
    }
  }

  redrawSvgElements() {
    const serverCount = this.props.serverCount
    const clientCount = this.props.clientCount

    const physicalAperture = physicalApertureWidth(
      1 / clientCount,
      1 / serverCount,
      this.props.aperture,
    )

    let intersectedServers: Array<{ ix: number; weight: number }> = []
    let intersectedServersIndices: Array<number> = []
    if (this.props.client !== null) {
      intersectedServers = this.props.serversByClientIx[this.props.client]
      intersectedServersIndices = intersectedServers.map(s => s.ix)
    }

    let intersectedClients: Array<number> = []
    if (this.props.server !== null) {
      intersectedClients = this.props.clientsByServerIx[this.props.server]
    }

    // clear canvas
    this.draw!.clear()

    const svgCenter = [600 / 2, 600 / 2]

    let text = ""
    if (this.props.server !== null) {
      text = `Server: ${this.props.server}`
      text += `\nWeight: ${this.props.ring.weights[this.props.server]}`
    } else if (this.props.client !== null) {
      text = `Client: ${this.props.client}`
    }

    this.draw!.text(text)
      .font({
        "font-size": "20px",
        family: "monospace",
        "letter-spacing": "-1pt",
      })
      .center(svgCenter[0], svgCenter[1])

    const serverCircleRadius = 200
    const serverCircleWidth = 40
    const serverSpacing = serverCount > 1 ? 0.5 / (1 + serverCount / 30) : 0
    const clientSpacing = clientCount > 1 ? 0.5 / (1 + clientCount / 80) : 0

    let accumulatedOffset = 0
    for (let i = 0; i < serverCount; i++) {
      const serverRingWeight = this.props.ring.ringWeights[i]

      const arc = describeArc(
        svgCenter[0],
        svgCenter[1],
        serverCircleRadius,
        360 * accumulatedOffset + serverSpacing,
        360 * accumulatedOffset + 360 * serverRingWeight - serverSpacing,
      )
      accumulatedOffset += serverRingWeight

      const colorInterp = interpolateRgb("#b8e186", "#78a93c")
      const intersectionIx = intersectedServersIndices.indexOf(i)

      const serverArc = this.draw!.path(arc)
        .attr({
          fill: "none",
          stroke:
            intersectionIx !== -1
              ? colorInterp(intersectedServers[intersectionIx].weight)
              : colorInterp(0),
          "stroke-width": serverCircleWidth,
        })
        .css("cursor", "pointer")
      if (this.props.server === i) {
        serverArc.attr({
          stroke: "#78a93c",
        })
      }

      serverArc.on("click", e => {
        e.stopPropagation()
        this.props.selectServer(i)
      })
    }

    const groups = []
    for (let i = 0; i < clientCount; i++) {
      const arcClient = describeArc(
        svgCenter[0],
        svgCenter[1],
        serverCircleRadius + serverCircleWidth + 3,
        i * (360 / clientCount) + clientSpacing,
        (i + 1) * (360 / clientCount) - clientSpacing,
      )

      const apertureStartAngle = (1 / clientCount) * i * 360
      const apertureEndAngle = apertureStartAngle + 360 * physicalAperture
      const apertureSpacing = physicalAperture < 1 ? clientSpacing : 0

      const apertureArc = describeArc(
        svgCenter[0],
        svgCenter[1],
        serverCircleRadius + (serverCircleWidth / 3) * 2 + 3,
        apertureStartAngle + apertureSpacing,
        apertureEndAngle - apertureSpacing,
      )

      const clientArcGroup = this.draw!.group()
      groups.push(clientArcGroup)

      const clientArcPath = clientArcGroup.path(arcClient).attr({
        fill: "none",
        stroke: intersectedClients.indexOf(i) !== -1 ? "#e574b9" : "#f1b6da",
        "stroke-width": serverCircleWidth,
      })
      let aperturePathGroup = clientArcGroup.group()
      clientArcGroup.css("cursor", "pointer")

      clientArcGroup.on("click", e => {
        e.stopPropagation()
        this.props.selectClient(i)
      })

      if (this.props.client === i) {
        clientArcPath.attr({
          stroke: "#e574b9",
        })

        aperturePathGroup.path(apertureArc).attr({
          fill: "none",
          stroke: "#cb529a",
          "stroke-width": serverCircleWidth / 3,
        })

        if (physicalAperture < 1) {
          const apertureStartLineStart = polarToCartesian(
            svgCenter[0],
            svgCenter[1],
            serverCircleRadius - serverCircleWidth * 2,
            apertureStartAngle,
          )
          const apertureStartLineEnd = polarToCartesian(
            svgCenter[0],
            svgCenter[1],
            serverCircleRadius + serverCircleWidth * 2,
            apertureStartAngle,
          )

          const apertureEndLineStart = polarToCartesian(
            svgCenter[0],
            svgCenter[1],
            serverCircleRadius - serverCircleWidth * 2,
            apertureEndAngle,
          )
          const apertureEndLineEnd = polarToCartesian(
            svgCenter[0],
            svgCenter[1],
            serverCircleRadius + serverCircleWidth * 2,
            apertureEndAngle,
          )

          aperturePathGroup
            .line(
              apertureStartLineStart.x,
              apertureStartLineStart.y,
              apertureStartLineEnd.x,
              apertureStartLineEnd.y,
            )
            .stroke({ width: 1, color: "#888", dasharray: "10,5" })

          aperturePathGroup
            .line(
              apertureEndLineStart.x,
              apertureEndLineStart.y,
              apertureEndLineEnd.x,
              apertureEndLineEnd.y,
            )
            .stroke({ width: 1, color: "#888", dasharray: "10,5" })
        }

        const apertureOuterArc = describeArc(
          svgCenter[0],
          svgCenter[1],
          serverCircleRadius + serverCircleWidth * 2,
          apertureStartAngle + apertureSpacing,
          apertureEndAngle - apertureSpacing,
        )

        aperturePathGroup.path(apertureOuterArc).attr({
          fill: "none",
          stroke: "#888",
          "stroke-width": 1,
          "stroke-dasharray": "10,5",
        })
      }
    }

    if (this.props.client !== null) {
      groups[this.props.client] && groups[this.props.client].front()
    }
  }

  componentDidUpdate() {
    this.redrawSvgElements()
  }

  render() {
    return (
      <div ref={this.svgWrapper} id={"svg-wrapper"}>
        <div ref={this.svg} id={"svg"} />
      </div>
    )
  }
}
