import WeightedRing from "./weighted-ring"

export default class WeightedDeterministicAperture {
  constructor(servers, coord, logicalAperture) {
    this.servers = servers
    this.loads = Array(servers.length).fill(0)
    this.coord = coord
    this.logicalAperture = Math.min(logicalAperture, servers.length)
    this.physicalAperture = physicalApertureWidth(
      this.coord.unitWidth,
      1 / this.servers.length,
      this.logicalAperture,
    )
    this.ring = new WeightedRing(servers)
  }

  pick() {
    const offset = this.coord.offset
    const width = this.physicalAperture

    const a = this.ring.pick(offset, width)
    const b = this.ring.tryPickSecond(a, offset, width)

    const aw = this.ring.weight(a, offset, width)
    const bw = this.ring.weight(b, offset, width)

    return this.choose(a, aw, b, bw)
  }

  choose(a, aw, b, bw) {
    const _aw = aw === 0 ? 1 : aw
    const _bw = bw === 0 ? 1 : bw

    if (this.loads[a] / _aw <= this.loads[b] / _bw) {
      this.loads[a] += 1
      return this.servers[a]
    } else {
      this.loads[b] += 1
      return this.servers[b]
    }
  }

  indices() {
    return this.ring.indices(this.coord.offset, this.physicalAperture)
  }

  weightedIndices() {
    return this.indices().map(ix => ({
      ix: ix,
      weight: this.ring.weight(ix, this.coord.offset, this.physicalAperture),
    }))
  }
}

export class Server {
  constructor(id, weight) {
    this.id = id
    this.weight = weight
  }
}

export class Client {
  constructor(instanceId, totalInstances) {
    this.unitWidth = 1 / totalInstances
    this.offset = instanceId * this.unitWidth
  }
}

export function physicalApertureWidth(
  localUnitWidth,
  remoteUnitWidth,
  logicalAperture,
) {
  // A recasting of the formula
  // clients*aperture <= N*servers
  // - N is the smallest integer satisfying the inequality and represents
  //   the number of times we have to circle the ring.
  // -> ceil(clients*aperture/servers) = N
  // - unitWidth = 1/clients; ring.unitWidth = 1/servers
  // -> ceil(aperture*ring.unitWidth/unitWidth) = N
  const unitWidth = localUnitWidth // (0, 1.0]

  const unitAperture = logicalAperture * remoteUnitWidth // (0, 1.0]
  const N = Math.ceil(unitAperture / unitWidth)
  const width = N * unitWidth
  // We know that `width` is bounded between (0, 1.0] since `N`
  // at most will be the inverse of `unitWidth` (i.e. if `unitAperture`
  // is 1, then units = 1/(1/x) = x, width = x*(1/x) = 1). However,
  // practically, we take the min of 1.0 to account for any floating
  // point stability issues.
  return Math.min(1.0, width)
}
