import React, { useState } from "react"

import WeightedDeterministicAperture, { Client, Server } from "./wda/wda"

import Vis from "./vis"
import { TableClientsByServer, TableServersByClient } from "./tables"
import WeightedRing from "./wda/weighted-ring"

export default () => {
  const [verboseState, setVerboseState] = useState(false)

  const [serverCount, setServerCount] = useState(5)
  const [clientCount, setClientCount] = useState(3)
  const [_aperture, setAperture] = useState(2)

  const [client, setClient] = useState(0)
  const [server, setServer] = useState(null)

  const [weights, setWeights] = useState(Array(serverCount).fill(100))
  const [hitTable, setHitTable] = useState(Array(serverCount).fill(0))

  const [lastTurn, setLastTurn] = useState(0)

  const aperture = Math.min(_aperture, serverCount)

  const servers = Array(serverCount)
    .fill(0)
    .map((_, ix) => new Server(ix, weights[ix]))

  const ring = new WeightedRing(servers)

  const serversByClientIx = Array(clientCount).fill(null)
  const clientsByServerIx = []

  const wdaByClient = []

  for (let i = 0; i < clientCount; i++) {
    const c = new Client(i, clientCount)
    const wda = new WeightedDeterministicAperture(servers, c, aperture)
    serversByClientIx[i] = wda.weightedIndices()
    wdaByClient[i] = wda

    serversByClientIx[i].forEach(s => {
      if (clientsByServerIx[s.ix] === undefined) {
        clientsByServerIx[s.ix] = []
      }

      if (clientsByServerIx[s.ix].indexOf(i) === -1) {
        clientsByServerIx[s.ix].push(i)
      }
    })
  }

  function simulateRounds(n) {
    setHitTable(prevState => {
      if (prevState === null) {
        prevState = Array(serverCount).fill(0)
      } else {
        prevState = [
          ...prevState.slice(0, serverCount),
          ...Array(Math.max(serverCount - prevState.length, 0)).fill(0),
        ]
      }

      let i = 0
      while (i < n) {
        const clientIx = (lastTurn + i) % clientCount
        const s = wdaByClient[clientIx].pick()
        prevState[s.id] += 1
        i++
      }

      setLastTurn(lastTurn + i)

      return prevState
    })
  }

  return (
    <div className={"vis-root"}>
      <div className={"vis-main"}>
        <div className="vis-controls">
          <p className="servers-slider">
            <label>
              <span className="label">
                <span>Servers</span>
              </span>
              <input
                type={"range"}
                value={serverCount}
                min={1}
                max={18}
                onChange={e => {
                  const newCount = parseInt(e.target.value, 10)
                  setServerCount(newCount)

                  // reset weights
                  setWeights(Array(newCount).fill(100))
                  setHitTable(Array(newCount).fill(0))
                }}
              />
              <span className="value">{serverCount}</span>
            </label>
          </p>
          <p className="clients-slider">
            <label>
              <span className="label">
                <span>Clients</span>
              </span>
              <input
                type={"range"}
                value={clientCount}
                min={1}
                max={90}
                onChange={e => setClientCount(parseInt(e.target.value, 10))}
              />
              <span className="value">{clientCount}</span>
            </label>
          </p>
          <p className="aperture-slider">
            <label>
              <span className="label">
                <span>Aperture</span>
              </span>
              <input
                type={"range"}
                value={aperture}
                min={1}
                max={serverCount}
                onChange={e => setAperture(parseInt(e.target.value, 10))}
              />
              <span className="value">{aperture}</span>
            </label>
          </p>
        </div>

        <Vis
          serverCount={serverCount}
          clientCount={clientCount}
          aperture={aperture}
          ring={ring}
          serversByClientIx={serversByClientIx}
          clientsByServerIx={clientsByServerIx}
          client={client < clientCount ? client : null}
          server={server < serverCount ? server : null}
          selectClient={ix => {
            setClient(ix)
            setServer(null)
          }}
          selectServer={ix => {
            setClient(null)
            setServer(ix)
          }}
        />
      </div>

      <details onToggle={e => setVerboseState(e.target.open)}>
        <summary>{verboseState ? "Hide" : "Show"} Verbose State</summary>

        {verboseState && (
          <div>
            <h2>Connections by client</h2>
            <TableServersByClient
              serversByClientIx={serversByClientIx}
              client={client}
              server={server}
            />

            <h2>Connections by server</h2>
            <div>
              <p>
                <input
                  type={"submit"}
                  value={"Simulate 1 round"}
                  onClick={() => simulateRounds(1)}
                />
                <input
                  type={"submit"}
                  value={"Simulate 1 000 rounds"}
                  onClick={() => simulateRounds(1000)}
                />
                <input
                  type={"submit"}
                  value={"Simulate 100 000 rounds"}
                  onClick={() => simulateRounds(100000)}
                />

                <input
                  type={"submit"}
                  value={"Reset hit stats"}
                  onClick={() => {
                    setHitTable(Array(serverCount).fill(0))
                  }}
                />
              </p>
            </div>
            <TableClientsByServer
              ring={ring}
              hitTable={hitTable}
              clientsByServerIx={clientsByServerIx}
              client={client}
              server={server}
              updateWeight={(ix, w) => {
                if (!Number.isInteger(w)) return
                setWeights(prevState => [
                  ...prevState.slice(0, ix),
                  w,
                  ...prevState.slice(ix + 1),
                ])
              }}
            />
          </div>
        )}
      </details>
    </div>
  )
}
