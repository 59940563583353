export default class WeightedRing {
  constructor(servers = []) {
    const weightsSum = servers.reduce((acc, v) => acc + v.weight, 0)

    this.weights = servers.map(s => s.weight)
    this.ringWeights = servers.map(s => s.weight / weightsSum)
  }

  /**
   * Pick a random index between [0, `size`) where the range of the
   * index intersects with [offset, offset + width).
   */
  pick(offset, width) {
    return this.index((offset + Math.random() * width) % 1.0)
  }

  /**
   * Picks a random index between [0, `size`) where the positions for the
   * respective index intersect with [offset, offset + width), so long as
   * the index is not `a` (if the range permits it).
   */
  tryPickSecond(a, offset, width) {
    const ab0 = this.widthUntil(a)
    const ab = ab0 + 1 < offset + width ? ab0 + 1 : ab0
    const ae = ab + this.serverWidth(a)

    const overlap = intersect(ab, ae, offset, offset + width)
    const rem = width - overlap

    // instead of `rem > 0` we need something better
    //  to avoid js floating point errors
    //  `rem` is at least 1/num clients, this constant is good enough
    if (rem > 1 / 100000) {
      // Instead of actually splitting the range into two, we offset
      // any pick that takes place in the second range if there is a
      // possibility that our second choice falls within [ab, ae].
      //
      // Note, special care must be taken to not bias towards ae + overlap, so
      // we treat the entire range greater than it uniformly.
      let pos = offset + Math.random() * rem
      if (pos >= ae - overlap) {
        pos += overlap
      }

      return this.index(pos % 1.0)
    } else {
      // The range [offset, offset + width) is equivalent to [ab, ae).
      return a
    }
  }

  /**
   * Returns the indices where [offset, offset + width) intersects.
   *
   * @note This returns the indices over which `pick` and `pick2` select.
   * Thus, we interpret a width of 0 as picking one index.
   */
  indices(offset, width) {
    const result = []

    let i = this.index(offset)
    let r = this.range(offset, width)

    while (r > 0) {
      const idx = i % this.ringWeights.length
      result.push(idx)
      i += 1
      r -= 1
    }

    return result
  }

  /**
   * Returns the total number of indices that [offset, offset + width) intersects with.
   *
   * @note This returns the number of indices over which `pick` and `pick2` select.
   * Thus, we interpret a width of 0 as picking one index.
   */
  range(offset, width) {
    if (width < 1.0) {
      const begin = this.index(offset)
      const end = this.index((offset + width) % 1.0)

      // We wrapped around the entire ring, so return the size.
      if (begin === end && width > this.serverWidth(begin)) {
        return this.ringWeights.length
      }
      // We only have one index to select from. Arguably, returning
      // a diff of zero here is correct too. However, in order to
      // project what `pick2` will do we return a range of 1.
      else if (begin === end) {
        return 1
      } else {
        const beginWeight = this.weight(begin, offset, width)
        const endWeight = this.weight(end, offset, width)

        // we want to project what `pick2` will do, so we need to
        // take into account the weight of `begin` and `end`.
        const adjustedBegin = beginWeight > 0 ? begin : begin + 1
        const adjustedEnd = endWeight > 0 ? end + 1 : end

        const diff = adjustedEnd - adjustedBegin
        if (diff <= 0) {
          return diff + this.ringWeights.length
        } else {
          return diff
        }
      }
    } else {
      // We know that `width == 1.0` in this case, meaning the entire
      // ring is within range.
      return this.ringWeights.length
    }
  }

  /**
   * Returns the (zero-based) index between [0, `size`) which the
   * position `offset` maps to.
   *
   * @param offset A value between [0, 1.0).
   */
  index(offset) {
    let partialSum = 0

    for (let i = 0; i < this.ringWeights.length; i++) {
      partialSum += this.ringWeights[i]

      if (partialSum === offset) {
        return (i + 1) % this.ringWeights.length
      }

      if (partialSum >= offset) {
        return i
      }
    }

    return this.ringWeights.length - 1
  }

  /**
   * Returns the ratio of the intersection between `index` and [offset, offset + width).
   */
  weight(index, offset, width) {
    const a = [
      this.widthUntil(index),
      this.widthUntil(index) + this.serverWidth(index),
    ]
    const b = [offset, offset + width]

    const [first, last] = a[0] < b[0] ? [a, b] : [b, a]

    let i = intersect(...first, ...last)
    // if we wraparound check for any intersection after the wraparound
    // we know that we wraparound at most once
    if (last[1] > 1) i += intersect(...first, 0, last[1] % 1.0)

    return i / (1 / this.weights.length)
  }

  widthUntil(ix) {
    return this.ringWeights.slice(0, ix).reduce((acc, v) => acc + v, 0)
  }

  serverWidth(ix) {
    return this.ringWeights[ix]
  }
}

/**
 * Returns the length of the intersection between the two ranges.
 *
 * Note this implementations assumes that the min(e0, e1) is greater
 *  than max(b0, b1). It's up to the caller to handle the case where
 *  the line segments wrap around the ring.
 */
export function intersect(b0, e0, b1, e1) {
  const len = Math.min(e0, e1) - Math.max(b0, b1)
  return Math.max(0.0, len)
}
