import { polarToCartesian } from "./geometry"

export function describeArc(x, y, outerRadius, startAngle, endAngle) {
  const isFullCircle = Math.abs(Math.abs(endAngle - startAngle) - 360) < 1
  const _endAngle = isFullCircle ? endAngle - 1 : endAngle

  const start = polarToCartesian(x, y, outerRadius, _endAngle)
  const end = polarToCartesian(x, y, outerRadius, startAngle)

  const arcSweep = _endAngle - startAngle <= 180 ? "0" : "1"

  // prettier-ignore
  const d = [
    "M", start.x, start.y,
    "A", outerRadius, outerRadius, 0, arcSweep, 0, end.x, end.y,
    isFullCircle ? "z" : ''
  ].join(" ");

  return d
}
